import React from 'react';
// import './Loading.css';  // Assuming you have a CSS file for styling the loading component

const Loading = () => {
  return (
    <div className="loading-spinner">
      {/* Your loading spinner implementation */}
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default Loading;
